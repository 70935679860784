import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useAuth } from "../src/contexts/AuthContext";
import { PageLoading } from "ui";

const Jobboard: React.FC = () => {
  const { currentUser } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!currentUser || currentUser.emailVerified === false) {
      router.replace("/login");
    } else {
      router.push("/profile");
    }
  }, [currentUser]);

  return <PageLoading />;
};

export default Jobboard;
